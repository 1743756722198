













































import { defineComponent, PropType } from '@nuxtjs/composition-api';

type Styles = 'horizontal' | 'vertical';
export default defineComponent({
  name: 'ProductPrices',
  props: {
    finalPrice: {
      type: [Number, String] as PropType<number | string>,
      default: null,
    },
    omnibusPrice: {
      type: [Number, String] as PropType<number | string>,
      default: null,
    },
    isVariantSelected: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    positioning: {
      type: String as PropType<Styles>,
      default: 'horizontal',
    },
    sku: {
      type: String as PropType<string>,
      default: '',
    },
  },
});
